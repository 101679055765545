<template>
<div class="sign-container">
    <van-row type="flex" justify="center">
        <van-image class="logo" width="100" height="100" radius="10" :src="logo" />
    </van-row>
    <van-row type="flex" justify="center">
        <div style="font-size:14px;color:#909092">欢迎使用途签电子签署服务</div>
    </van-row>
    <van-row type="flex" justify="center">
        <div class="home-clues" v-if="contract.status==1">您有一份合同文件需要签署</div>
        <div class="home-clues" v-if="contract.status==2">该合同已签署</div>
        <div class="home-clues" v-if="contract.status==3">该合同文件已作废</div>
    </van-row>
    <van-row type="flex" justify="center" align="center" class="contract-name-wrap">
        合同名称: {{contract.name}}
    </van-row>
    <van-row v-if="contract.status==1" type="flex" justify="center" class="contract-agreement-wrap">
        <van-checkbox v-model="checked">我同意采用电子签名方式在此设备上签署上述协议文件。</van-checkbox>
    </van-row>
    <van-button block v-if="contract.status==1" type="info" :to="{name:'SignPreview'}" :disabled="!checked" >下一步</van-button>
    <van-button block v-if="contract.status==2" @click="mailSend">发送到邮箱</van-button>
    <van-button block v-if="contract.status==2" @click="showDownloadConfirmDialog=true;verifyCode=''">下载合同</van-button>
    <van-dialog :z-index="2001" v-model="showDownloadConfirmDialog" title="请输入手机号后四位（用于验证）" show-cancel-button @confirm="getDownloadUrl">
        <van-password-input
            :value="verifyCode"
            :mask="false"
            :gutter="10"
            :length="4"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            />
    </van-dialog>
    <van-number-keyboard
            v-model="verifyCode"
            :z-index="2002"
            :show="showKeyboard"
            @blur="showKeyboard = false"
            />
    <van-row type="flex" justify="center" class="footer">
        深圳途签科技有限公司 版权所有 
        <!-- <a href="http://www.beian.miit.gov.cn" target="_blank">粤ICP备13089516号-5</a> -->
    </van-row>
</div>
</template>

<script>
import logo from '@/assets/logo.png';
import {reqSendMail,reqFormalDownloadPdf} from '@/api/contract'
export default {
    data() {
        return {
            logo: logo,
            checked: false,
            id:'',
            showDownloadConfirmDialog:false,
            showKeyboard:false,
            verifyCode:''
        }
    },
    computed:{
        contract() {
            return this.$store.state.contract.contract
        }
    },
    mounted(){
        this.id = this.$route.params.id
        this.$store.dispatch('contract/remoteGetContract',this.id)
        this.$store.commit('contract/setContractId',this.id)
    },
    methods:{
        mailSend(){
            reqSendMail(this.id).then(res=>{
                if(res.ret){
                    this.$toast.success('合同已发送至预留邮箱')
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        getDownloadUrl(){
            reqFormalDownloadPdf(this.id,this.verifyCode).then(res=>{
                if(res.ret){
                    window.open(res.data)
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.sign-container {
    .logo {
        margin-top: 50px;
    }

    .home-clues {
        font-size: 0.9rem;
        color: #ababad;
        margin: 0.5rem;
    }

    .contract-name-wrap {
        height: 3rem;
        border-top: 1px #e5e5e5 solid;
        border-bottom: 1px #e5e5e5 solid;
        background-color: #fff;
    }

    .contract-agreement-wrap {
        padding: 1rem;
        font-size: 14px;
        color: #ababad;
    }

    .van-button{
        margin-top:10px;
    }

    .footer {
        position: fixed;
        width: 100%;
        bottom: 3px;
        font-size: 12px;
    }
}
</style>
