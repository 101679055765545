import request from '@/utils/request'

export function reqContractStatus(id) {
    return request({
        url: `/contract/status/${id}`,
        method: 'get',
    })
}

export function reqSendMail(id) {
    return request({
        url: `/contract/mail/send/${id}`,
        method: 'get',
    })
}

export function reqFormalDownloadPdf(id,code) {
    return request({
        url: `/contract/formal/pdf/${id}/${code}`,
        method: 'get',
    })
}